"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = exports.numberToString = exports.stringToNumber = exports.validateEmail = void 0;
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) && (email === null || email === void 0 ? void 0 : email.length) < 254;
}
exports.validateEmail = validateEmail;
function getDecimalSeparator(locale) {
    var numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale)
        .formatToParts(numberWithDecimalSeparator)
        .find(function (part) { return part.type === 'decimal'; })
        .value;
}
function getGroupSeparator(locale) {
    return Intl.NumberFormat(locale)
        .formatToParts('1000')
        .find(function (part) { return part.type === 'group'; })
        .value;
}
function stringToNumber(str) {
    if (!str)
        return str;
    var locale = navigator.language || navigator.userLanguage;
    var separator = getDecimalSeparator(locale);
    if (separator == '.')
        return +str;
    return +String(str).replace(new RegExp(separator, 'g'), '.');
}
exports.stringToNumber = stringToNumber;
function numberToString(num) {
    if (num == null)
        return '';
    var locale = navigator.language || navigator.userLanguage;
    var separator = getDecimalSeparator(locale);
    if (separator == '.')
        return String(num);
    return String(num).replace(new RegExp('\\.', 'g'), separator);
}
exports.numberToString = numberToString;
function formatNumber(num) {
    if (!num)
        return num;
    var locale = navigator.language || navigator.userLanguage;
    var separator = getGroupSeparator(locale);
    return new Intl.NumberFormat(locale).format(num, { maximumSignificantDigits: 3 }).replace(new RegExp(separator, 'g'), '');
}
exports.formatNumber = formatNumber;
