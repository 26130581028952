"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countryList = exports.currencyValues = exports.paymentMeans = exports.DOCUMENT_TYPES = exports.DOCUMENT_TYPES_BY_LEVEL = exports.DOCUMENT_VALUE_TYPES = exports.ObjectTypes = void 0;
exports.ObjectTypes = {
    AP_RECEIPT: "RECPT",
    AR_INVOICE: "ARINV",
    AP_INVOICE: "APINV",
    AR_CREDIT_NOTE: "ARCRN",
    AP_CREDIT_NOTE: "APCRN",
    AP_STATEMENT: "APSTM",
    BANK_STATEMENT: "BKSTM",
    OTHER: "OTHER",
    ARCHIVED_DOCUMENT: "ARCIV",
    PAYMENT: "PAYMT",
};
exports.DOCUMENT_VALUE_TYPES = {
    MULTIPLE_DOCUMENTS: 'MULTIPLE_DOCUMENTS',
    INCOMPLETE_DOCUMENT: 'INCOMPLETE_DOCUMENT',
    BAD_QUALITY_DOCUMENT: 'BAD_QUALITY_DOCUMENT',
    JUNK_DOCUMENT: 'JUNK_DOCUMENT',
    SPLIT_DOCUMENT: 'SPLIT_DOCUMENT',
    RECEIPT: 'RECEIPT',
    INVOICE: 'INVOICE',
    CREDIT_NOTE: 'CREDIT_NOTE',
    CASH_INVOICE: 'CASH_INVOICE',
    REMINDER: 'REMINDER',
    COLLECTION_NOTICE: 'COLLECTION_NOTICE',
    PROFORMA_INVOICE: 'PROFORMA_INVOICE',
    CREDIT_NOTE_LINK_URL: 'CREDIT_NOTE_LINK_URL',
    SALES_CONTRACT: 'SALES_CONTRACT',
    SALES_ORDER: 'SALES_ORDER',
    SALES_SUMMARY: 'SALES_SUMMARY',
    DAY_END_CLOSING: 'DAY_END_CLOSING',
    DELIVERY_NOTE: 'DELIVERY_NOTE',
    QUOTATION: 'QUOTATION',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS',
    CUSTOMS_DECLARATION: 'CUSTOMS_DECLARATION',
    CUSTOMS_DECLARATION_STATEMENT: 'CUSTOMS_DECLARATION_STATEMENT',
    PAYROLL_PAYMENT_LIST: 'PAYROLL_PAYMENT_LIST',
    PAYROLL_POSTINGS: 'PAYROLL_POSTINGS',
    PAYROLL_STATEMENT: 'PAYROLL_STATEMENT',
    TAX_PAYMENT_INFORMATION: 'TAX_PAYMENT_INFORMATION',
    LOAN_REPAYMENT_INFORMATION: 'LOAN_REPAYMENT_INFORMATION',
    CASH_DEPOSIT_RECEIPT: 'CASH_DEPOSIT_RECEIPT',
    PAYMENT_SETTLEMENT_REPORT: 'PAYMENT_SETTLEMENT_REPORT',
    ANNUAL_ALLOCATIONS: 'ANNUAL_ALLOCATIONS',
    BANK_STATEMENT: 'BANK_STATEMENT',
    AP_STATEMENT: 'AP_STATEMENT',
    ANNUAL_REPORT: 'ANNUAL_REPORT',
    INVENTORY_COUNTING_REPORT: 'INVENTORY_COUNTING_REPORT',
    TRAVEL_EXPENSE_REPORT: 'TRAVEL_EXPENSE_REPORT',
    MILEAGE_LOG: 'MILEAGE_LOG',
    ANNUAL_REPORT_BANK: 'ANNUAL_REPORT_BANK',
    ANNUAL_REPORT_INSURANCE_PENSION: 'ANNUAL_REPORT_INSURANCE_PENSION',
    TRIAL_BALANCE: 'TRIAL_BALANCE',
    INCOME_STATEMENT: 'INCOME_STATEMENT',
    BALANCE_SHEET: 'BALANCE_SHEET',
    RENTAL_AGREEMENT: 'RENTAL_AGREEMENT',
    LOAN_AGREEMENT: 'LOAN_AGREEMENT',
    EMPLOYMENT_AGREEMENT: 'EMPLOYMENT_AGREEMENT',
    BYLAWS_OF_THE_COMPANY: 'BYLAWS_OF_THE_COMPANY',
    CONFIRMATION_OF_SHARE_CONTRIBUTION: 'CONFIRMATION_OF_SHARE_CONTRIBUTION',
    INCORPORATION_DOCUMENT: 'INCORPORATION_DOCUMENT',
    PAYROLL_BASIS: 'PAYROLL_BASIS',
    TAX_PAYMENT_COLLECTION_NOTICE: 'TAX_PAYMENT_COLLECTION_NOTICE',
    POSTING_CORRECTION: 'POSTING_CORRECTION',
    DUPLICATE_DOCUMENT_CHECK: 'DUPLICATE_DOCUMENT_CHECK',
    WRONG_CLASSIFICATION: 'WRONG_CLASSIFICATION',
    PAYROLL_TAX_SETTLEMENT: 'PAYROLL_TAX_SETTLEMENT',
    OTHER: 'OTHER',
};
exports.DOCUMENT_TYPES_BY_LEVEL = [
    { name: 'Multiple documents', document_type: exports.DOCUMENT_VALUE_TYPES.MULTIPLE_DOCUMENTS },
    { name: 'Split document', document_type: exports.DOCUMENT_VALUE_TYPES.SPLIT_DOCUMENT, hidden: true },
    { name: 'Duplicate document check', document_type: exports.DOCUMENT_VALUE_TYPES.DUPLICATE_DOCUMENT_CHECK, hidden: true },
    { name: 'Wrong classification', document_type: exports.DOCUMENT_VALUE_TYPES.WRONG_CLASSIFICATION, hidden: true },
    { name: 'Incomplete document', document_type: exports.DOCUMENT_VALUE_TYPES.INCOMPLETE_DOCUMENT },
    { name: 'Bad quality document', document_type: exports.DOCUMENT_VALUE_TYPES.BAD_QUALITY_DOCUMENT },
    { name: 'Logo / junk', document_type: exports.DOCUMENT_VALUE_TYPES.JUNK_DOCUMENT },
    {
        name: 'Single document of good quality',
        child: [
            {
                name: 'Sales / purchase document',
                child: [
                    { name: 'Receipt', document_type: exports.DOCUMENT_VALUE_TYPES.RECEIPT },
                    { name: 'Invoice', document_type: exports.DOCUMENT_VALUE_TYPES.INVOICE },
                    { name: 'Cash invoice', document_type: exports.DOCUMENT_VALUE_TYPES.CASH_INVOICE },
                    { name: 'Credit note', document_type: exports.DOCUMENT_VALUE_TYPES.CREDIT_NOTE },
                    { name: 'Payment reminder', document_type: exports.DOCUMENT_VALUE_TYPES.REMINDER },
                    { name: 'Collection notice', document_type: exports.DOCUMENT_VALUE_TYPES.COLLECTION_NOTICE },
                    { name: 'Proforma invoice', document_type: exports.DOCUMENT_VALUE_TYPES.PROFORMA_INVOICE },
                    { name: 'Sales order', document_type: exports.DOCUMENT_VALUE_TYPES.SALES_ORDER },
                    { name: 'Delivery note / packing slip', document_type: exports.DOCUMENT_VALUE_TYPES.DELIVERY_NOTE },
                    { name: 'Quotation / estimate', document_type: exports.DOCUMENT_VALUE_TYPES.QUOTATION },
                    { name: 'Sales summary for period', document_type: exports.DOCUMENT_VALUE_TYPES.SALES_SUMMARY },
                    { name: 'Purchase / sales contract', document_type: exports.DOCUMENT_VALUE_TYPES.SALES_CONTRACT },
                    { name: 'Day-end closing / Z-Report', document_type: exports.DOCUMENT_VALUE_TYPES.DAY_END_CLOSING },
                    { name: 'Customs declaration', document_type: exports.DOCUMENT_VALUE_TYPES.CUSTOMS_DECLARATION },
                    { name: 'Customs summary statement (govt)', document_type: exports.DOCUMENT_VALUE_TYPES.CUSTOMS_DECLARATION_STATEMENT },
                ]
            },
            {
                name: 'Payment document',
                child: [
                    { name: 'Payment details (only)', document_type: exports.DOCUMENT_VALUE_TYPES.PAYMENT_DETAILS },
                    { name: 'Tax payment information', document_type: exports.DOCUMENT_VALUE_TYPES.TAX_PAYMENT_INFORMATION },
                    { name: 'Loan repayment information', document_type: exports.DOCUMENT_VALUE_TYPES.LOAN_REPAYMENT_INFORMATION },
                    { name: 'Receipt for cash deposit', document_type: exports.DOCUMENT_VALUE_TYPES.CASH_DEPOSIT_RECEIPT },
                    { name: 'Settlement report from payment provider', document_type: exports.DOCUMENT_VALUE_TYPES.PAYMENT_SETTLEMENT_REPORT },
                    { name: 'Payroll payment list', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_PAYMENT_LIST },
                    { name: 'Payroll tax settlement', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_TAX_SETTLEMENT },
                ]
            },
            {
                name: 'Report',
                child: [
                    { name: 'Sales summary for period', document_type: exports.DOCUMENT_VALUE_TYPES.SALES_SUMMARY },
                    { name: 'Settlement report from payment provider', document_type: exports.DOCUMENT_VALUE_TYPES.PAYMENT_SETTLEMENT_REPORT },
                    { name: 'Day-end closing / Z-Report', document_type: exports.DOCUMENT_VALUE_TYPES.DAY_END_CLOSING },
                    { name: 'Annual allocations / final postings', document_type: exports.DOCUMENT_VALUE_TYPES.ANNUAL_ALLOCATIONS },
                    { name: 'Inventory counting report', document_type: exports.DOCUMENT_VALUE_TYPES.INVENTORY_COUNTING_REPORT },
                    { name: 'Travel expense report', document_type: exports.DOCUMENT_VALUE_TYPES.TRAVEL_EXPENSE_REPORT },
                    { name: 'Mileage log', document_type: exports.DOCUMENT_VALUE_TYPES.MILEAGE_LOG },
                    { name: 'Bank statement', document_type: exports.DOCUMENT_VALUE_TYPES.BANK_STATEMENT },
                    { name: 'AP statement', document_type: exports.DOCUMENT_VALUE_TYPES.AP_STATEMENT },
                    { name: 'Annual report (bank)', document_type: exports.DOCUMENT_VALUE_TYPES.ANNUAL_REPORT_BANK },
                    { name: 'Annual report (insurance / pension)', document_type: exports.DOCUMENT_VALUE_TYPES.ANNUAL_REPORT_INSURANCE_PENSION },
                    { name: 'Annual report (other)', document_type: exports.DOCUMENT_VALUE_TYPES.ANNUAL_REPORT },
                    { name: 'Trial balance', document_type: exports.DOCUMENT_VALUE_TYPES.TRIAL_BALANCE },
                    { name: 'Income statement', document_type: exports.DOCUMENT_VALUE_TYPES.INCOME_STATEMENT },
                    { name: 'Balance sheet', document_type: exports.DOCUMENT_VALUE_TYPES.BALANCE_SHEET },
                ]
            },
            {
                name: 'Contract / agreement',
                child: [
                    { name: 'Purchase / sales contract', document_type: exports.DOCUMENT_VALUE_TYPES.SALES_CONTRACT },
                    { name: 'Rental agreement', document_type: exports.DOCUMENT_VALUE_TYPES.RENTAL_AGREEMENT },
                    { name: 'Loan agreement', document_type: exports.DOCUMENT_VALUE_TYPES.LOAN_AGREEMENT },
                    { name: 'Employment agreement', document_type: exports.DOCUMENT_VALUE_TYPES.EMPLOYMENT_AGREEMENT },
                    { name: 'Bylaws of the company', document_type: exports.DOCUMENT_VALUE_TYPES.BYLAWS_OF_THE_COMPANY },
                    { name: 'Confirmation of share contribution', document_type: exports.DOCUMENT_VALUE_TYPES.CONFIRMATION_OF_SHARE_CONTRIBUTION },
                    { name: 'Incorporation document', document_type: exports.DOCUMENT_VALUE_TYPES.INCORPORATION_DOCUMENT },
                ]
            },
            {
                name: 'Payroll',
                child: [
                    { name: 'Payroll / salary basis', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_BASIS },
                    { name: 'Payroll postings', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_POSTINGS },
                    { name: 'Payroll payment list', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_PAYMENT_LIST },
                    { name: 'Payroll tax settlement', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_TAX_SETTLEMENT },
                    { name: 'Payroll statement (for reconciliation)', document_type: exports.DOCUMENT_VALUE_TYPES.PAYROLL_STATEMENT },
                ]
            },
            {
                name: 'Tax and government fees',
                child: [
                    { name: 'Tax payment information', document_type: exports.DOCUMENT_VALUE_TYPES.TAX_PAYMENT_INFORMATION },
                    { name: 'Tax payment reminder / collection notice', document_type: exports.DOCUMENT_VALUE_TYPES.TAX_PAYMENT_COLLECTION_NOTICE },
                ]
            },
            {
                name: 'Accounting documents',
                child: [
                    { name: 'Annual allocations / final postings', document_type: exports.DOCUMENT_VALUE_TYPES.ANNUAL_ALLOCATIONS },
                    { name: 'Trial balance', document_type: exports.DOCUMENT_VALUE_TYPES.TRIAL_BALANCE },
                    { name: 'Posting correction documentation', document_type: exports.DOCUMENT_VALUE_TYPES.POSTING_CORRECTION },
                ]
            },
            {
                name: 'Other / unknown',
                document_type: exports.DOCUMENT_VALUE_TYPES.OTHER
            }
        ]
    }
];
var getAllDocTypes = function () {
    var result = [];
    var handleChild = function (docs) {
        docs.forEach(function (doc) {
            if (doc.document_type && result.findIndex(function (d) { return d.document_type == doc.document_type; }) == -1) {
                result.push(doc);
            }
            if (doc.child && doc.child.length > 0) {
                handleChild(doc.child);
            }
        });
    };
    handleChild(exports.DOCUMENT_TYPES_BY_LEVEL);
    return result;
};
exports.DOCUMENT_TYPES = getAllDocTypes();
exports.paymentMeans = [
    {
        value: 'CARD',
        label: 'Card'
    },
    {
        value: 'BANK_TRANSFER',
        label: 'Bank Transfer'
    },
    {
        value: 'DIRECT_DEBIT',
        label: 'Direct Debit'
    },
    {
        value: 'CASH',
        label: 'Cash'
    },
    {
        value: 'UNKNOWN',
        label: 'Not stated / unknown'
    },
];
exports.currencyValues = [
    {
        label: '$',
        value: 'USD'
    },
    {
        label: '€',
        value: 'EUR'
    },
    {
        label: 'NOK',
        value: 'NOK'
    },
    {
        label: 'SEK',
        value: 'SEK'
    },
    {
        label: 'DKK',
        value: 'DKK'
    },
    {
        label: '£',
        value: 'GBP'
    },
];
exports.countryList = {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas (the)",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory (the)",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands (the)",
    "CF": "Central African Republic (the)",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands (the)",
    "CO": "Colombia",
    "KM": "Comoros (the)",
    "CD": "Congo (the Democratic Republic of the)",
    "CG": "Congo (the)",
    "CK": "Cook Islands (the)",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "CI": "Côte d'Ivoire",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic (the)",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (the) [Malvinas]",
    "FO": "Faroe Islands (the)",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories (the)",
    "GA": "Gabon",
    "GM": "Gambia (the)",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See (the)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (the Democratic People's Republic of)",
    "KR": "Korea (the Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic (the)",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands (the)",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (the Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands (the)",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger (the)",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands (the)",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines (the)",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "MK": "Republic of North Macedonia",
    "RO": "Romania",
    "RU": "Russian Federation (the)",
    "RW": "Rwanda",
    "RE": "Réunion",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan (the)",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands (the)",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates (the)",
    "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UM": "United States Minor Outlying Islands (the)",
    "US": "United States of America (the)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland Islands"
};
