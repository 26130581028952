"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConstCallback = void 0;
var react_1 = require("react");
function useConstCallback(func, deps) {
    var ref = (0, react_1.useRef)(func);
    (0, react_1.useMemo)(function () {
        ref.current = func;
    }, deps);
    return (0, react_1.useCallback)(function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        return ref.current.apply(ref, params);
    }, []);
}
exports.useConstCallback = useConstCallback;
