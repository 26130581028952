"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigateAndReset = exports.navigate = exports.navigationRef = void 0;
var native_1 = require("@react-navigation/native");
exports.navigationRef = (0, native_1.createNavigationContainerRef)();
function navigate(name, params) {
    if (exports.navigationRef.isReady()) {
        exports.navigationRef.navigate(name, params);
    }
}
exports.navigate = navigate;
function navigateAndReset(name, params) {
    if (exports.navigationRef.isReady()) {
        exports.navigationRef.reset({
            index: 0,
            routes: [
                {
                    name: name,
                    params: params,
                },
            ],
        });
    }
}
exports.navigateAndReset = navigateAndReset;
