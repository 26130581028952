"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var native_base_1 = require("native-base");
var CustomButton = (0, react_1.forwardRef)(function (props, ref) {
    var onPress = props.onPress, isLoading = props.isLoading, isLoadingText = props.isLoadingText, style = props.style, _text = props._text, _spinner = props._spinner, children = props.children, width = props.width, height = props.height, marginBottom = props.marginBottom, marginTop = props.marginTop, padding = props.padding;
    var s = style[0] ? style[0] : style;
    var borderRadius = s.borderRadius, backgroundColor = s.backgroundColor;
    return (react_1.default.createElement(native_base_1.Button, { ref: ref, style: style, width: width, height: height, _important: {
            width: width,
            height: height,
            borderRadius: borderRadius,
            backgroundColor: backgroundColor,
            marginBottom: marginBottom,
            marginTop: marginTop,
            paddingTop: padding || s.padding || '10px', paddingBottom: padding || s.padding || '10px',
            paddingLeft: padding || s.padding || '12px', paddingRight: padding || s.padding || '12px'
        }, _web: {
            width: width,
            height: height,
            borderRadius: borderRadius,
            backgroundColor: backgroundColor,
            marginBottom: marginBottom,
            marginTop: marginTop,
            paddingTop: padding || s.padding || '10px', paddingBottom: padding || s.padding || '10px',
            paddingLeft: padding || s.padding || '12px', paddingRight: padding || s.padding || '12px'
        }, _text: _text, isFocusVisible: false, onPress: onPress, isLoading: isLoading, _spinner: _spinner, isLoadingText: isLoadingText }, children));
});
CustomButton.name = 'CustomButton';
exports.default = CustomButton;
