"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var native_base_1 = require("native-base");
var react_native_1 = require("react-native");
var CustomInput = function (props) {
    var isInvalid = props.isInvalid, isRequired = props.isRequired, invalidMsg = props.invalidMsg, placeholder = props.placeholder, label = props.label, type = props.type, inputLeftElement = props.inputLeftElement, inputRightElement = props.inputRightElement, onChange = props.onChange, value = props.value, style = props.style, inputStyle = props.inputStyle, subLabel = props.subLabel, isViewMode = props.isViewMode, others = __rest(props, ["isInvalid", "isRequired", "invalidMsg", "placeholder", "label", "type", "inputLeftElement", "inputRightElement", "onChange", "value", "style", "inputStyle", "subLabel", "isViewMode"]);
    var inputRef = (0, react_1.useRef)(null);
    var _a = (0, react_1.useState)(false), isFocused = _a[0], setIsFocused = _a[1];
    var _b = (0, react_1.useState)(null), currentValue = _b[0], setCurrentValue = _b[1];
    var handleOnChange = function (text) {
        if (text != currentValue) {
            setCurrentValue(text);
            onChange && onChange(text);
        }
    };
    (0, react_1.useEffect)(function () {
        inputRef.current.value = value || '';
    }, [value]);
    return (react_1.default.createElement(native_base_1.FormControl, { style: __assign(__assign({}, styles.form), (style || {})), isInvalid: isInvalid, isRequired: isRequired },
        label && react_1.default.createElement(native_base_1.FormControl.Label, { _text: styles.label }, label),
        subLabel && react_1.default.createElement(native_base_1.FormControl.Label, { _text: styles.subLabel }, subLabel),
        react_1.default.createElement(native_base_1.View, { style: __assign(__assign(__assign({}, styles.inputBox), (isFocused ? styles.focused : {})), (isInvalid ? styles.invalid : {})) },
            react_1.default.createElement(native_base_1.Input, __assign({ ref: inputRef, InputLeftElement: inputLeftElement, InputRightElement: inputRightElement, type: type || 'text', style: __assign({ height: 48, fontSize: 16 }, (inputStyle || {})), autoCorrect: false, placeholder: placeholder, defaultValue: value, variant: "unstyled", isDisabled: isViewMode, onBlur: function (e) {
                    handleOnChange(e.target.value);
                    setIsFocused(false);
                }, onFocus: function () {
                    setIsFocused(true);
                } }, others))),
        react_1.default.createElement(native_base_1.FormControl.ErrorMessage, { leftIcon: react_1.default.createElement(native_base_1.WarningOutlineIcon, { size: "xs" }) }, invalidMsg)));
};
var styles = react_native_1.StyleSheet.create({
    form: {
        marginTop: 4
    },
    inputBox: {
        borderColor: '#d4d4d4',
        borderWidth: 1,
        borderRadius: 4
    },
    label: {
        color: '#171E44',
        fontSize: 14
    },
    subLabel: {
        color: '#929191',
        fontSize: 11,
        marginTop: '-10px',
        fontWeight: 300
    },
    invalid: {
        borderColor: '#dc2626',
        borderWidth: 1
    },
    focused: {
        borderColor: '#2684FF',
        borderWidth: 1
    }
});
exports.default = (0, react_1.memo)(CustomInput);
