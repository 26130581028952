"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var native_base_1 = require("native-base");
var react_native_1 = require("react-native");
var react_i18next_1 = require("react-i18next");
var Suggestions = function (props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var suggestions = props.suggestions, field = props.field, onClick = props.onClick, zIndex = props.zIndex, getLabel = props.getLabel, isViewMode = props.isViewMode;
    // console.log('field: ', field);
    var _a = (0, react_1.useState)([]), data = _a[0], setData = _a[1];
    var fields;
    if (field instanceof Array) {
        fields = field;
    }
    else {
        fields = [field];
    }
    var filterField = function (s) {
        var result = [];
        for (var idx = 0; idx < fields.length; idx++) {
            var f = fields[idx];
            if (s[f]) {
                result.push(s[f]);
            }
        }
        return result;
    };
    (0, react_1.useEffect)(function () {
        if (suggestions) {
            if (!isViewMode) {
                var sug = (suggestions || []).map(function (s) { return s.data; }).map(function (s) { return filterField(s); }).filter(function (value, index, self) {
                    return value && value.length > 0 && self.map(function (s) { return s[0]; }).indexOf(value[0]) === index;
                });
                setData(sug);
                if (sug.length == 1) {
                    var val = typeof getLabel == 'function' ? getLabel(sug[0][0]) : sug[0][0];
                    onClick && onClick(val, sug[0]);
                }
            }
            else {
                var sug = (suggestions || []).filter(function (r) { return r.status == 'CONSENSUS'; }).map(function (s) { return s.data; })
                    .map(function (s) { return filterField(s); }).filter(function (value, index, self) {
                    return value && value.length > 0;
                });
                if (sug.length > 0) {
                    var val = typeof getLabel == 'function' ? getLabel(sug[0][0]) : sug[0][0];
                    onClick && onClick(val, sug[0]);
                }
                sug = (suggestions || []).filter(function (r) { return r.status == 'ACCEPTED'; }).map(function (s) { return s.data; })
                    .map(function (s) { return filterField(s); }).filter(function (value, index, self) {
                    return value && value.length > 0;
                });
                setData(sug);
            }
        }
        else {
            setData([]);
        }
    }, [suggestions]);
    if (isViewMode) {
        return (react_1.default.createElement(native_base_1.View, { style: __assign(__assign({}, styles.view), { zIndex: zIndex }) },
            react_1.default.createElement(native_base_1.Text, { style: { fontSize: 13, color: '#999999', paddingBottom: 3, paddingLeft: 5 } }, t('Your selection')),
            (!data || data.length == 0) && react_1.default.createElement(native_base_1.View, null,
                react_1.default.createElement(native_base_1.Text, { style: { paddingLeft: 5, paddingRight: 5, fontSize: '11px', paddingTop: '3px', color: '#e55d5d' } }, "Not Selected")),
            data.map(function (s) {
                var val = typeof getLabel == 'function' ? getLabel(s[0]) : s[0];
                if (Array.isArray(val)) {
                    val = val.join('\n');
                }
                else if (typeof val === 'object') {
                    console.log('val: ', JSON.stringify(val));
                    val = JSON.stringify(val);
                }
                return react_1.default.createElement(native_base_1.View, { key: s },
                    react_1.default.createElement(native_base_1.Pressable, { accessibilityRole: "suggestion" }, function (_a) {
                        var isHovered = _a.isHovered, isPressed = _a.isPressed;
                        return react_1.default.createElement(native_base_1.Box, { bg: (isHovered || isPressed) ? "coolGray.200" : "white", style: styles.box },
                            react_1.default.createElement(native_base_1.Text, { style: { paddingLeft: 5, paddingRight: 5 } }, val));
                    }));
            })));
    }
    if (!data || data.length == 0) {
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    return (react_1.default.createElement(native_base_1.View, { style: __assign(__assign({}, styles.view), { zIndex: zIndex }) },
        react_1.default.createElement(native_base_1.Text, { style: { fontSize: 13, color: '#999999', paddingBottom: 3, paddingLeft: 5 } }, t('Suggestions')),
        data.map(function (s) {
            var val = typeof getLabel == 'function' ? getLabel(s[0]) : s[0];
            if (Array.isArray(val)) {
                val = val.join('\n');
            }
            else if (typeof val === 'object') {
                console.log('val: ', JSON.stringify(val));
                val = JSON.stringify(val);
            }
            return react_1.default.createElement(native_base_1.View, { key: s },
                react_1.default.createElement(native_base_1.Pressable, { onPress: function () {
                        onClick && onClick(val, s);
                    }, accessibilityRole: "suggestion" }, function (_a) {
                    var isHovered = _a.isHovered, isPressed = _a.isPressed;
                    return react_1.default.createElement(native_base_1.Box, { bg: (isHovered || isPressed) ? "coolGray.200" : "white", style: styles.box },
                        react_1.default.createElement(native_base_1.Text, { style: { paddingLeft: 5, paddingRight: 5 } }, val));
                }));
        })));
};
var styles = react_native_1.StyleSheet.create({
    box: {
        paddingVertical: 7,
        position: 'relative',
        marginLeft: 0
    },
    view: {
        marginTop: -3,
        marginBottom: 5,
        border: '1px solid rgb(212, 212, 212)',
        borderRadius: 4,
        paddingVertical: 5,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        backgroundColor: 'white'
    }
});
exports.default = Suggestions;
