"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.showErrorNotification = exports.showSuccessNotification = void 0;
var react_native_1 = require("react-native");
var native_base_1 = require("native-base");
var ToastAlert = function (_a) {
    var id = _a.id, status = _a.status, variant = _a.variant, title = _a.title, description = _a.description, isClosable = _a.isClosable, rest = __rest(_a, ["id", "status", "variant", "title", "description", "isClosable"]);
    return React.createElement(native_base_1.Alert, __assign({ maxWidth: react_native_1.Platform.OS == 'web' ? '100%' : '90%', minWidth: 250, alignSelf: "center", flexDirection: "row", status: status ? status : "info", variant: variant }, rest),
        React.createElement(native_base_1.VStack, { space: 1, flexShrink: 1, w: "100%" },
            React.createElement(native_base_1.HStack, { flexShrink: 1, alignItems: "center", justifyContent: "space-between" },
                React.createElement(native_base_1.HStack, { space: 2, flexShrink: 1, alignItems: "center" },
                    React.createElement(native_base_1.Alert.Icon, null),
                    React.createElement(native_base_1.Text, { fontSize: "md", fontWeight: "medium", flexShrink: 1, color: variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null }, title)),
                isClosable ? React.createElement(native_base_1.IconButton, { variant: "unstyled", icon: React.createElement(native_base_1.CloseIcon, { size: "3" }), _icon: {
                        color: variant === "solid" ? "lightText" : "darkText"
                    }, onPress: function () { return native_base_1.Toast.close(id); } }) : null),
            React.createElement(native_base_1.Text, { px: "6", color: variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null }, description)));
};
function showSuccessNotification(msg) {
    if (!native_base_1.Toast.isActive(msg)) {
        native_base_1.Toast.show({
            id: msg,
            duration: 4000,
            placement: react_native_1.Platform.OS == 'web' ? 'bottom-right' : 'top',
            render: function (_a) {
                var id = _a.id;
                return React.createElement(ToastAlert, { id: id, title: "Error", description: msg, variant: "top-accent", status: "success", isClosable: true });
            }
        });
    }
}
exports.showSuccessNotification = showSuccessNotification;
function showErrorNotification(error) {
    var msg = error && error.toString ? error.toString() : (error || 'Something went wrong. Please try again');
    if (!native_base_1.Toast.isActive(msg)) {
        native_base_1.Toast.show({
            id: msg,
            duration: 4000,
            placement: react_native_1.Platform.OS == 'web' ? 'bottom-right' : 'top',
            render: function (_a) {
                var id = _a.id;
                return React.createElement(ToastAlert, { id: id, title: "Error", description: msg, variant: "top-accent", status: "error", isClosable: true });
            }
        });
    }
}
exports.showErrorNotification = showErrorNotification;
