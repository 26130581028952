"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var native_base_1 = require("native-base");
var react_native_1 = require("react-native");
var react_i18next_1 = require("react-i18next");
var useConstCallback_1 = require("./useConstCallback");
var croppers = {};
var selectedMaskIndex;
var ImageBox = function (_a) {
    var onLoad = _a.onLoad, src = _a.src, scrollHeight = _a.scrollHeight, _b = _a.imgId, imgId = _b === void 0 ? 0 : _b, _c = _a.isSplitByRow, isSplitByRow = _c === void 0 ? false : _c;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _d = (0, react_1.useState)(false), imageLoaded = _d[0], changeImageLoaded = _d[1];
    var cropper = croppers[imgId];
    var options = {
        checkCrossOrigin: false, modal: false, viewMode: 0,
        autoCrop: false, dragMode: 'move',
        toggleDragModeOnDblclick: false,
        // crop(event) {
        //   var data = event.detail;
        //   console.log(JSON.stringify(data))
        // },
        cropend: function (event) {
            var action = event.detail.action;
            if (selectedMaskIndex == null && action != 'move' && action != 'crop') {
                console.log('cropend: ', event.detail);
                document.getElementById('addThisMaskBtn').style.display = 'table';
                document.querySelector('#addThisMaskBtn > span').innerText = t('Add This Mask');
                document.querySelector('#addThisMaskBtn > span').setAttribute('data-original-title', t('Add the current mask to the list'));
            }
        },
        // zoom(event) {
        //   const oldRatio = event.detail.oldRatio;
        //   const ratio = event.detail.ratio;
        //   console.log('zoom: ', oldRatio, ratio, ratio / oldRatio);
        // },
        // crop(event) {
        //   console.log('crop: ', event.detail);
        // }
    };
    function setButtonsVisibility(display) {
        if (display === void 0) { display = 'table'; }
        document.getElementById('deleteMaskBtn').style.display = display;
        document.getElementById('addThisMaskBtn').style.display = display;
        document.getElementById('cancelMaskBtn').style.display = display;
    }
    var ev = {
        setMask: function (mask) {
            setButtonsVisibility('none');
            sendMessage('setMask', { mask: mask, selectedMaskIndex: selectedMaskIndex });
        },
        deleteSelectedMask: function () {
            if (selectedMaskIndex != null) {
                sendMessage('deleteSelectedMask', selectedMaskIndex);
                cropper.clear();
                setButtonsVisibility('none');
                selectedMaskIndex = null;
            }
        },
        clear: function () {
            console.log('imgId: ', imgId);
            sendMessage('clear');
            cropper.clear();
            setButtonsVisibility('none');
        },
        cancel: function () {
            if (selectedMaskIndex != null) {
                document.getElementById('idx' + selectedMaskIndex).style.display = 'block';
                selectedMaskIndex = null;
                cropper.clear();
                setButtonsVisibility('none');
            }
        }
    };
    var sendMessage = function (type, data) {
        var _a;
        console.log("Sending message: ", type, data);
        (_a = window.parent) === null || _a === void 0 ? void 0 : _a.postMessage({ type: 'ImageBox', message: { type: type, data: data } }, "*");
    };
    var handleImageLoaded = function () {
        changeImageLoaded(true);
        onLoad && onLoad();
    };
    function initCropper() {
        if (!cropper) {
            var imageViewerContainer = document.getElementById('imageViewerContainer' + imgId);
            var imageViewer = document.getElementById('imageViewer' + imgId);
            if (imageViewer) {
                imageViewerContainer.style.width = imageViewer.offsetWidth + 'px';
                imageViewerContainer.style.height = (window.innerHeight / (isSplitByRow ? 2 : 1)) + 'px';
                croppers[imgId] = new Cropper(imageViewer, options);
                cropper = croppers[imgId];
                $('[data-toggle="tooltip"]').tooltip();
            }
        }
    }
    function initDragMode() {
        if (cropper) {
            var imageViewer = document.getElementById('imageViewer' + imgId);
            if (imageViewer) {
                var newOptions = __assign({}, options);
                newOptions.dragMode = 'crop';
                newOptions.viewMode = 1;
                newOptions.toggleDragModeOnDblclick = true;
                cropper.destroy();
                croppers[imgId] = new Cropper(imageViewer, newOptions);
                cropper = croppers[imgId];
            }
            if (document.getElementById('move-crop-groups')) {
                document.getElementById('move-crop-groups').style.display = 'block';
            }
            $('[data-toggle="tooltip"]').tooltip();
        }
    }
    function drawMasks(masks) {
        var div = document.querySelector('.cropper-container');
        document.querySelectorAll('.cropper-crop-box-mask').forEach(function (e) { return e.remove(); });
        for (var idx = 0; idx < masks.length; idx++) {
            var mask = __assign({}, masks[idx]);
            var box = document.createElement('div');
            box.id = 'idx' + idx;
            box.className = 'cropper-crop-box-mask';
            box.style.cssText = "\n        width: ".concat(mask.w2, "px;\n        height: ").concat(mask.h2, "px;\n        border: 2px dashed #cd4848b3;\n        transform: translate(").concat(mask.left2, "px, ").concat(mask.top2, "px);\n      ");
            box.addEventListener('click', function () {
                var boxIdx = parseInt(this.id.substring(3));
                console.log('boxIdx: ', boxIdx);
                cropper.crop();
                cropper.setCropBoxData({
                    left: masks[boxIdx].left2,
                    top: masks[boxIdx].top2,
                    width: masks[boxIdx].w2,
                    height: masks[boxIdx].h2
                });
                if (selectedMaskIndex != null) {
                    document.getElementById('idx' + selectedMaskIndex).style.display = 'block';
                }
                document.getElementById(this.id).style.display = 'none';
                selectedMaskIndex = boxIdx;
                setButtonsVisibility();
                document.querySelector('#addThisMaskBtn > span').innerText = t('Save Change');
                document.querySelector('#addThisMaskBtn > span').setAttribute('data-original-title', t('Saves all changes'));
            });
            div.insertBefore(box, div.lastChild);
        }
    }
    var eventListener = (0, useConstCallback_1.useConstCallback)(function (ev) {
        if (ev.data.type === 'Extraction') {
            console.log("Received message: ", ev.data);
            var msg = (ev.data.message || {}).type;
            var data = (ev.data.message || {}).data;
            if (msg == 'initCropperDragMode') {
                initDragMode();
            }
            else if (msg == 'drawMasks') {
                drawMasks(data);
            }
            else if (msg == 'reset') {
                console.log('imgId: ', imgId);
                cropper.destroy();
                croppers[imgId] = null;
                cropper = null;
                document.getElementById('imageViewerContainer' + imgId).style.width = '';
                document.getElementById('imageViewerContainer' + imgId).style.height = '';
                initCropper();
            }
        }
    });
    (0, react_1.useEffect)(function () {
        console.log("Adding event listener");
        window.addEventListener("message", eventListener);
        return function () {
            console.log('destroy cropper...');
            if (cropper) {
                cropper.destroy();
                cropper = null;
            }
            selectedMaskIndex = null;
            window.removeEventListener("message", eventListener);
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (imageLoaded) {
            if (cropper) {
                cropper.destroy();
                cropper = null;
            }
            selectedMaskIndex = null;
            initCropper();
        }
    }, [imageLoaded]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(native_base_1.ScrollView, { height: scrollHeight || '100vh', style: { paddingLeft: 0, paddingRight: 0, backgroundColor: 'white' } },
            !imageLoaded && react_1.default.createElement(react_native_1.View, { style: {
                    paddingTop: 80, position: 'absolute', left: '50%',
                    transform: 'translateX(-50%)'
                } },
                react_1.default.createElement(native_base_1.Spinner, { size: 40, color: "indigo.400", accessibilityLabel: "Loading File..." })),
            react_1.default.createElement(react_native_1.View, { id: 'imageViewerContainer' + imgId, style: { position: 'relative' } },
                react_1.default.createElement("img", { id: 'imageViewer' + imgId, className: "image", src: src, alt: t('The document url is invalid'), onLoad: handleImageLoaded }))),
        imageLoaded && react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(react_native_1.View, { style: { position: 'absolute', bottom: '0px', right: '0px', padding: '5px', zIndex: 1, display: 'flex', flexDirection: 'row' } },
                react_1.default.createElement("button", { type: "button", className: "btn btn-primary", id: "addThisMaskBtn", style: { marginRight: '5px', padding: '5px 10px', display: 'none', outline: 'none', boxShadow: 'none' }, onClick: function () {
                        var cropBox = cropper.getCropBoxData();
                        var data = cropper.getData();
                        ev.setMask({
                            left2: cropBox.left,
                            top2: cropBox.top,
                            w2: cropBox.width,
                            h2: cropBox.height,
                            left: parseInt(data.x),
                            top: parseInt(data.y),
                            w: parseInt(data.width),
                            h: parseInt(data.height),
                            data: data
                        });
                        cropper.clear();
                    } },
                    react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", "data-original-title": t('Add the current mask to the list'), style: { padding: '0px', fontSize: '13px', lineHeight: 'unset', display: 'table-cell' } }, "Add This Mask")),
                react_1.default.createElement("button", { type: "button", className: "btn btn-danger", id: "deleteMaskBtn", style: { marginRight: '5px', padding: '5px 10px', display: 'none', outline: 'none', boxShadow: 'none' }, onClick: function () {
                        ev.deleteSelectedMask();
                    } },
                    react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", "data-original-title": t('Delete the selected mask'), style: { padding: '0px', fontSize: '13px', lineHeight: 'unset', display: 'table-cell' } }, "Delete")),
                react_1.default.createElement("button", { type: "button", className: "btn btn-warning", id: "cancelMaskBtn", style: { marginRight: '5px', padding: '5px 10px', display: 'none', outline: 'none', boxShadow: 'none' }, onClick: function () {
                        ev.cancel();
                    } },
                    react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", "data-original-title": t('Cancel'), style: { padding: '0px', fontSize: '13px', lineHeight: 'unset', display: 'table-cell' } }, "Cancel")),
                react_1.default.createElement("div", { className: "btn-group", id: "move-crop-groups", style: { marginRight: '5px', display: 'none' } },
                    react_1.default.createElement("button", { type: "button", className: "btn btn-secondary", style: { padding: '3px', outline: 'none', boxShadow: 'none' }, onClick: function () { return cropper.setDragMode('move'); } },
                        react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", title: "", "data-original-title": t('Move Mode'), style: { padding: '0.375rem 0.75rem' } },
                            react_1.default.createElement("span", { className: "fa fa-arrows-alt" }))),
                    react_1.default.createElement("button", { type: "button", className: "btn btn-secondary", style: { padding: '3px', outline: 'none', boxShadow: 'none' }, onClick: function () { return cropper.setDragMode('crop'); } },
                        react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", title: "", "data-original-title": t('Crop Move'), style: { padding: '0.375rem 0.75rem' } },
                            react_1.default.createElement("span", { className: "fa fa-crop-alt" })))),
                react_1.default.createElement("div", { className: "btn-group", style: { marginRight: '5px' } },
                    react_1.default.createElement("button", { type: "button", className: "btn btn-secondary", style: { padding: '3px', outline: 'none', boxShadow: 'none' }, onClick: function () { return cropper.zoom(0.1); } },
                        react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", title: "", "data-original-title": t('Zoom In'), style: { padding: '0.375rem 0.75rem' } },
                            react_1.default.createElement("span", { className: "fa fa-search-plus" }))),
                    react_1.default.createElement("button", { type: "button", className: "btn btn-secondary", style: { padding: '3px', outline: 'none', boxShadow: 'none' }, onClick: function () { return cropper.zoom(-0.1); } },
                        react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", title: "", "data-original-title": t('Zoom Out'), style: { padding: '0.375rem 0.75rem' } },
                            react_1.default.createElement("span", { className: "fa fa-search-minus" })))),
                react_1.default.createElement("button", { type: "button", className: "btn btn-secondary", style: { padding: '3px', outline: 'none', boxShadow: 'none' }, onClick: function () {
                        ev.clear();
                        cropper.reset();
                        // cropper.zoomTo(1);
                    } },
                    react_1.default.createElement("span", { className: "docs-tooltip", "data-toggle": "tooltip", "data-original-title": t('Reset'), style: { padding: '0.375rem 0.75rem' } },
                        react_1.default.createElement("span", { className: "fa fa-sync-alt" })))))));
};
exports.default = (0, react_1.memo)(ImageBox);
