import React from 'react';
import { AppRegistry } from 'react-native';
import App from './dist/src/components/App';
import { name as appName } from './app.json';

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('react-native-web-app'),
});
